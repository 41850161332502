export default {
    goToPlanning: (_) => ({
        name: 'planning'
	}),
	goToPlanningGyneco: (_) => ({
		name: 'planning',
		params: {
			tab: 'REPRODUCTION'
		}
	}),
	goToHorseFichePlanning: (params) => ({
		name: 'HorseFichePlanning',
		params: { // on envoie pas directement params pour des raisons de sécu
			horse_id: params.horse_id
		}
	}),
	goToHorseFichePlanningGyneco: (params) => ({
		name: 'HorseGynecologie',
		params: { // on envoie pas directement params pour des raisons de sécu
			horse_id: params.horse_id
		}
	}),
	goToOrdonnanceAjout: (params) => ({
		name: 'ordonnanceAjout',
		params: {
			horse_id: params.horse_id || 0
		}
	}),
	goToMouvementListe: (_) => ({
		name: 'mouvementListe'
	}),
	goToGynecoStats: (_) => ({
		name: 'statistiquesRepro'
	})
}
