<template>
    <div class="container-fluid h-100 my-2">
        <div class="row justify-content-center align-items-center h-100">
            <div class="col-auto">
                <div class="box" id="login">
                    <groomy-logo class="logo_center_bis"/>
                    <p class="text-center message_chargement mb-0 mt-4">
                        <font-awesome-icon class="spinner_home" :icon="['fas', 'spinner']" pulse /><br/>{{ $t('global.redirecting') }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>


<script type="text/javascript">
	import NotificationActions from '@/assets/js/notification_actions.js'
	import NotificationMixin from '@/mixins/Notification.js'

	export default {
		name: "NotificationHandler",
		mixins: [NotificationMixin],
		created() {
			this.handleNotification()
		},
		methods: {
			async handleNotification() {
				const action = this.$route.params.action
				const params = JSON.parse(this.$route.params.params || '{}')
				const notif_id = this.$route.params.notif_id
				let route = { name: 'horseListe' }

				await this.markNotificationAsRead(notif_id)

				if (NotificationActions[action]) {
					route = NotificationActions[action](params)
				}
				this.$router.replace(route)
			}
		}
	}
</script>
